// Compiler result to JSON file
    import { createComponent } from 'js/declViewModelService';
    import { registerDynImportEntry } from 'js/moduleLoader';
    import AwParseService from 'js/awParseService';
    import AwFilterService from 'js/awFilterService';
    import { getField } from 'js/utils';
    import { Fragment } from 'react';
    let filterSer = AwFilterService.instance;
    import AwTextbox from 'viewmodel/AwTextboxViewModel';
import AwPassword from 'viewmodel/AwPasswordViewModel';
import AwLinkWithPopup from 'viewmodel/AwLinkWithPopupViewModel';
import AwButton from 'viewmodel/AwButtonViewModel';
import AwGuidanceMessage from 'viewmodel/AwGuidanceMessageViewModel';
    import { AwClass,VisibleWhen,ExistWhen } from 'js/hocCollection';
const AwButtonAwClass = AwClass(AwButton);
const DivVisibleWhen = VisibleWhen('div');
const DivExistWhen = ExistWhen('div');
AwButtonAwClass.displayName = 'AwButtonAwClass';
DivVisibleWhen.displayName = 'DivVisibleWhen';
DivExistWhen.displayName = 'DivExistWhen';

    

    /*
    ctx imports
    */
    
    const ctxImportObj = {  };
    

    /*
    dynamic import
    */
    registerDynImportEntry('js/loginUtils', () => import('js/loginUtils'));
registerDynImportEntry('js/AwPageService', () => import('js/AwPageService'));;

    /*
    view model
    */
    const declViewModelJson = {
    "schemaVersion": "1.0.0",
    "imports": [],
    "actions": {
        "initialize": {
            "actionType": "batchJob",
            "steps": [
                {
                    "action": "setBrowsertitle"
                },
                {
                    "action": "initializeData"
                },
                {
                    "action": "intializeDataProvider"
                }
            ]
        },
        "intializeDataProvider": {
            "actionType": "dataProvider",
            "method": "localeList"
        },
        "initializeData": {
            "actionType": "JSFunctionAsync",
            "method": "initializeData",
            "inputData": {
                "localeTextLink": "{{data.localeTextLink}}",
                "username": "{{data.username}}",
                "password": "{{data.password}}",
                "browserTitle": "{{data.browserTitle}}"
            },
            "outputData": {
                "brandName": "brandName",
                "productName": "productName",
                "productVersion": "productVersion",
                "companyName": "companyName",
                "copyright": "copyright",
                "localeTextLink": "localeTextLink",
                "localeListTotalFound": "totalFound",
                "localeList": "localeList"
            },
            "deps": "js/loginUtils"
        },
        "loginAction": {
            "actionType": "JSFunctionAsync",
            "method": "signIn",
            "inputData": {
                "username": "{{data.username.dbValue}}",
                "password": "{{data.password.dbValue}}"
            },
            "outputData": {
                "loginInfo": "response",
                "loginError": "error"
            },
            "deps": "js/loginUtils"
        },
        "setBrowsertitle": {
            "actionType": "JSFunctionAsync",
            "method": "getBrowserTitle",
            "outputData": {
                "browserTitle": ""
            },
            "deps": "js/AwPageService"
        },
        "setSpinnerStatus": {
            "actionType": "JSFunction",
            "method": "setSpinnerStatus",
            "inputData": {
                "spinnerStatus": "{{data.spinnerStatus}}"
            },
            "outputData": {
                "spinnerStatus": "spinnerStatus",
                "buttonClicked": true
            },
            "deps": "js/loginUtils"
        },
        "updateLocale": {
            "actionType": "JSFunction",
            "method": "updateLocale",
            "inputData": {
                "localeValue": "{{data.localeTextLink}}"
            },
            "deps": "js/loginUtils"
        }
    },
    "dataProviders": {
        "localeList": {
            "dataProviderType": "Static",
            "response": "{{data.localeList}}",
            "totalFound": "{{data.localeListTotalFound}}"
        }
    },
    "data": {
        "username": {
            "displayName": "{{i18n.USER_NAME_PLACEHOLDER}}",
            "type": "STRING",
            "isRequired": "true",
            "isEditable": "true",
            "uiValue": "",
            "dbValue": "",
            "requiredText": "{{i18n.USER_NAME_PLACEHOLDER}}",
            "autofocus": true
        },
        "password": {
            "displayName": "{{i18n.PASSWORD_PLACEHOLDER}}",
            "isRequired": true,
            "uiValue": "",
            "dbValue": "",
            "requiredText": "{{i18n.PASSWORD_PLACEHOLDER}}",
            "type": "STRING"
        },
        "localeTextLink": {
            "displayName": "English",
            "type": "STRING",
            "isRequired": "true",
            "displayValues": []
        },
        "loginText": {
            "displayName": "{{i18n.LOGIN_TEXT}}",
            "type": "STRING"
        },
        "loggingInText": {
            "displayName": "{{i18n.LOGGING_IN_TEXT}}",
            "type": "STRING"
        },
        "noLoginError": {
            "status": "conditions.noLoginError"
        }
    },
    "messages": {
        "loginError": {
            "messageType": "ERROR",
            "messageText": "{{data.loginError}}"
        }
    },
    "conditions": {
        "isLoginButtonEnabled": {
            "expression": "data.username.dbValue != '' && data.password.dbValue != ''"
        },
        "noLoginError": {
            "expression": "data.setSpinnerStatus || (data.buttonClicked && !data.loginError)"
        }
    },
    "onEvent": [
        {
            "eventId": "progress.start",
            "action": "setSpinnerStatus"
        },
        {
            "eventId": "progress.end",
            "action": "setSpinnerStatus"
        }
    ],
    "i18n": {
        "USER_NAME_PLACEHOLDER": [
            "LoginMessages"
        ],
        "PASSWORD_PLACEHOLDER": [
            "LoginMessages"
        ],
        "LOGIN_TEXT": [
            "LoginMessages"
        ],
        "LOGGING_IN_TEXT": [
            "LoginMessages"
        ],
        "VERSION_TEXT": [
            "LoginMessages"
        ],
        "COPYRIGHT_DATE_TEXT": [
            "LoginMessages"
        ],
        "COPYRIGHT_MESSAGE": [
            "LoginMessages"
        ]
    },
    "lifecycleHooks": {
        "onMount": "initialize",
        "onUpdate": {
            "action": "updateLocale",
            "observers": [
                "viewModel.data.localeTextLink"
            ]
        }
    },
    "_viewModelId": "AwLoginPage",
    "_uniqueViewModelId": "AwLoginPage",
    "ctx": {}
};

    /*
    <div class='signin-form aw-layout-mainView' >
    <div class='aw-login-loginViewPanel'>
        <div class='aw-login-tcLoginView'>
            <div class='aw-login-companyLogoParent'>
                <div class='aw-login-companyLogo'></div>
            </div>
            <div class='aw-login-signInParent'>
                <div class='aw-login-signIn'>
                    <div class="aw-login-brandPanel">
                        <div class="aw-login-brandLogo"></div>
                        <div class='aw-login-brandText'>
                            <h3 class='aw-login-companyName'>{ data.companyName }</h3>
                            <h2 class='aw-login-productName'>{ data.productName }</h2>
                        </div>
                    </div>
                    <div>
                        <div class="aw-login-username"><aw-textbox prop="data.username" action='loginAction'></aw-textbox></div>
                        <div><aw-password prop="data.password" action='loginAction'></aw-password></div>
                        <div>
                            <div class='aw-login-signInContainer'>
                                <div class='aw-login-locale aw-layout-flexColumnContainer'>
                                    <aw-link-with-popup prop="data.localeTextLink" dataprovider='dataProviders.localeList'></aw-link-with-popup>
                                </div>
                                <div class='aw-login-signInButton'>
                                    <aw-button type='submit' button-type='sole' action='loginAction' aw-class="{'disabled': !conditions.isLoginButtonEnabled}">
                                        <div class="loading" visible-when="data.spinnerStatus === 'Active' || (data.buttonClicked && !data.loginError)">
                                            {i18n.LOGGING_IN_TEXT}<span class="dot dot1">.</span><span class="dot dot2">.</span><span class="dot dot3">.</span>
                                        </div>
                                        <div visible-when="!(data.spinnerStatus === 'Active' || (data.buttonClicked && !data.loginError))">
                                            {data.loginText.propertyDisplayName}
                                        </div>
                                    </aw-button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div class='aw-login-progressContainer'>
                        <div class='aw-login-errorMessage' exist-when="data.loginError">
                            <aw-guidance-message message="loginError" closable="false" banner-style="true" show-icon="false" show-type="false"></aw-guidance-message>
                        </div>
                    </div>
                </div>
            </div>
            <div class="aw-login-copyright">
                <p class="aw-login-copyrightTitle">{i18n.VERSION_TEXT} {data.productVersion}</p>
                <p class="aw-login-copyrightMessage">{i18n.COPYRIGHT_MESSAGE}</p>
                <p class="aw-login-copyrightBase">{i18n.COPYRIGHT_DATE_TEXT} { data.copyright }.</p>
            </div>
        </div>
    </div>
</div>

    */
    const renderFn =(props)=> {
        let subPanelContext = props.subPanelContext ;
        let { viewModel: { data, dataProviders, dispatch, conditions, selectionModels, editHandlers, chartProviders, ports  }, grids, ctx, actions, fields, messages, i18n, formProp, viewPath  } = props;
        data = {...data, dataProviders};
        if( subPanelContext && subPanelContext.fields ){
            fields = { ...fields, ...subPanelContext.fields };
        }
        let $index = null;
        return (
                <div className="signin-form aw-layout-mainView">
        <div className="aw-login-loginViewPanel">
            <div className="aw-login-tcLoginView">
                <div className="aw-login-companyLogoParent">
                    <div className="aw-login-companyLogo">
                    </div>
                </div>
                <div className="aw-login-signInParent">
                    <div className="aw-login-signIn">
                        <div className="aw-login-brandPanel">
                            <div className="aw-login-brandLogo">
                            </div>
                            <div className="aw-login-brandText">
                                <h3 className="aw-login-companyName">
                                    { data.companyName }
                                </h3>
                                <h2 className="aw-login-productName">
                                    { data.productName }
                                </h2>
                            </div>
                        </div>
                        <div>
                            <div className="aw-login-username">
                                <AwTextbox {...getField("data.username", fields, $index, null, null )} action={actions.loginAction}>
                                </AwTextbox>
                            </div>
                            <div>
                                <AwPassword {...getField("data.password", fields, $index, null, null )} action={actions.loginAction}>
                                </AwPassword>
                            </div>
                            <div>
                                <div className="aw-login-signInContainer">
                                    <div className="aw-login-locale aw-layout-flexColumnContainer">
                                        <AwLinkWithPopup {...getField("data.localeTextLink", fields, $index, null, null )} dataprovider={AwParseService.instance( "dataProviders.localeList" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                                        </AwLinkWithPopup>
                                    </div>
                                    <div className="aw-login-signInButton">
                                        <AwButtonAwClass type="submit" buttonType="sole" action={actions.loginAction} awClass={AwParseService.instance( "{'disabled': !conditions.isLoginButtonEnabled}" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                                            <DivVisibleWhen className="loading" visibleWhen={AwParseService.instance( "data.spinnerStatus === 'Active' || (data.buttonClicked && !data.loginError)" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                                                {i18n.LOGGING_IN_TEXT}
                                                <span className="dot dot1">
                                                    .
                                                </span>
                                                <span className="dot dot2">
                                                    .
                                                </span>
                                                <span className="dot dot3">
                                                    .
                                                </span>
                                            </DivVisibleWhen>
                                            <DivVisibleWhen visibleWhen={AwParseService.instance( "!(data.spinnerStatus === 'Active' || (data.buttonClicked && !data.loginError))" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                                                {data.loginText.propertyDisplayName}
                                            </DivVisibleWhen>
                                        </AwButtonAwClass>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="aw-login-progressContainer">
                            <DivExistWhen className="aw-login-errorMessage" existWhen={AwParseService.instance( "data.loginError" )( { props, data, fields, dataProviders, ctx, i18n, actions, subPanelContext, viewPath, conditions, formProp, dispatch, selectionModels, editHandlers, chartProviders, ports } )}>
                                <AwGuidanceMessage message={messages.loginError} closable="false" bannerStyle="true" showIcon="false" showType="false">
                                </AwGuidanceMessage>
                            </DivExistWhen>
                        </div>
                    </div>
                </div>
                <div className="aw-login-copyright">
                    <p className="aw-login-copyrightTitle">
                        {i18n.VERSION_TEXT} {data.productVersion}
                    </p>
                    <p className="aw-login-copyrightMessage">
                        {i18n.COPYRIGHT_MESSAGE}
                    </p>
                    <p className="aw-login-copyrightBase">
                        {i18n.COPYRIGHT_DATE_TEXT} { data.copyright }.
                    </p>
                </div>
            </div>
        </div>
    </div>
        )
    };


    /*
    define component
    */
    const Component = createComponent( declViewModelJson, renderFn, undefined, ctxImportObj );

    export default Component;